<template>
  <div class="app-details-overview">
    <modal-app-metrics-new :is-metrics-data-loaded="metricsDataLoaded"
                           classes="upper">
      <template v-slot:metrics-items>
        <div class="metrics-grid-wrap">
          <div class="metrics-grid-item">
            <div class="metrics-icon">
              <svg-icon v-if="hideInstalls" icon="locked-metric"/>
              <svg-icon v-else icon="installs-metrics-icon"/>
            </div>
            <div v-if="hideInstalls"
                 class="metric-value upgrade-text"
                 @click="openPlansModal">
              Pro Plan
              <svg-icon icon="arrow-right"/>
            </div>
            <div v-else class="metric-value">
              {{ appMetricsData?.installs ?? '' }}
            </div>
            <div class="metrics-title">
              <template v-if="currentApp?.store?.key === 'APP_STORE'">
                Last Month Installs
              </template>
              <template v-else>
                Total Installs
              </template>
              <div class="tooltip-trigger">
                <svg-icon icon="question-circle-icon"/>
                <div class="tooltip-text">
                  An install takes place when a user has downloaded an app and successfully opens it for the first time.
                  Installs are a core part of the acquisition cycle.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="metrics-grid-wrap">
          <div class="metrics-grid-item">
            <div class="metrics-icon">
              <svg-icon v-if="hideInstalls" icon="locked-metric"/>
              <svg-icon v-else icon="revenue-metrics-icon"/>
            </div>
            <div v-if="hideInstalls"
                 class="metric-value upgrade-text"
                 @click="openPlansModal">
              Pro Plan
              <svg-icon icon="arrow-right"/>
            </div>
            <div v-else class="metric-value">
              {{ appMetricsData?.revenue ? '$' + appMetricsData?.revenue : '' }}
            </div>
            <div class="metrics-title">
              Last Month Revenue
            </div>
          </div>
        </div>
        <div class="metrics-grid-wrap">
          <div class="metrics-grid-item">
            <div class="metrics-icon">
              <svg-icon icon="metrics-rating"/>
            </div>
            <div class="metric-value">{{ appMetricsData?.rating ?? '' }}</div>
            <div class="metrics-title">
              App Rating in
              <div class="visibility-block">
                <dynamic-image classes="flag-icon"
                               :width="16"
                               :height="12"
                               :country-code="currentCountry?.code"/>
              </div>
            </div>
          </div>
        </div>
        <div class="metrics-grid-wrap">
          <div class="metrics-grid-item">
            <div class="metrics-icon">
              <svg-icon icon="metrics-votes"/>
            </div>
            <div class="metric-value">{{ appMetricsData?.voted ?? '' }}</div>
            <div class="metrics-title">
              Votes in
              <div class="visibility-block">
                <dynamic-image classes="flag-icon"
                               :width="16"
                               :height="12"
                               :country-code="currentCountry?.code"/>
              </div>
            </div>
          </div>
        </div>
        <div class="metrics-grid-wrap">
          <div class="metrics-grid-item">
            <div class="metrics-icon">
              <svg-icon icon="visibility-metrics-icon"/>
            </div>
            <div class="metric-value">{{ appMetricsData?.visibility ?? '' }}</div>
            <div class="metrics-title">
              Visibility in
              <div class="visibility-block">
                <dynamic-image classes="flag-icon"
                               :width="16"
                               :height="12"
                               :country-code="currentCountry?.code"/>
              </div>
            </div>
          </div>
        </div>
        <div class="metrics-grid-wrap">
          <div class="metrics-grid-item">
            <div class="metrics-icon">
              <svg-icon icon="metrics-category-visibility"/>
            </div>
            <div class="metric-value">{{ appMetricsData?.category_visibility_percent ?? '' }}%</div>
            <div class="metrics-title">
              Cat. Visibility in
              <div class="visibility-block">
                <dynamic-image classes="flag-icon"
                               :width="16"
                               :height="12"
                               :country-code="currentCountry?.code"/>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:preloader-block>
        <div class="preloader-wrap">
          <preloader-table-small loader-size="50px"></preloader-table-small>
        </div>
      </template>
    </modal-app-metrics-new>

    <div class="tab-component-title screenshots">Screenshots</div>
    <modal-app-screenshots :screenshots-data="screenshotsComputed"
                           :is-screenshots-data-loaded="screenshotsDataLoaded"
                           :top-locales-data="preparedScreenshotsLocales"
                           :current-prop-locale="countryMainLocale"
                           :current-competitor-store="appData.store">
      <template v-slot:upper-options>
        <div class="upper-options">
          <div class="device-list">
            <template v-if="appData.store === 'APP_STORE'">
              <div class="device-item"
                   @click="changeDevice('iphone')"
                   :class="{active: currentDevice === 'iphone'}">iPhone
              </div>
              <div class="device-item"
                   @click="changeDevice('ipad')"
                   :class="{active: currentDevice === 'ipad'}">iPad
              </div>
            </template>
          </div>
          <div class="localization-dropdown">
            <div class="localization-title">Localization:</div>
            <custom-dropdown v-if="preparedScreenshotsLocales?.length > 0"
                             :close-dropdown="closeLocalesList">
              <template v-slot:header>
                {{ countryMainLocale.title }}
              </template>
              <template v-slot:content>
                <div class="content-items-list">
                  <div class="dropdown-item"
                       @click="localeItemClicked(item)"
                       v-for="item in preparedScreenshotsLocales">
                    {{ item.value }}
                    <template v-if="item.countryValue">
                      ({{ item.countryValue }})
                    </template>
                  </div>
                </div>
              </template>
            </custom-dropdown>
          </div>
        </div>
      </template>

      <template v-slot:loader>
        <div class="preloader-wrap">
          <preloader-table-small loader-size="50px"></preloader-table-small>
        </div>
      </template>
    </modal-app-screenshots>

    <div class="app-details-tabs-content">
      <text-metadata :app-data="appData"
                     :filter-locales-array="filterLocalesArray"
                     :filter-countries-array="filterCountriesArray">
      </text-metadata>
    </div>

    <metrics-data :app-data="appData"></metrics-data>

    <modal-app-bottom-details :app-data="appData" :app-store="appData?.store"></modal-app-bottom-details>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import PreloaderTableSmall from "@/components/UI/PreloaderTableSmall/index.vue";
import CustomDropdown from "@/components/UI/CustomDropdown/index.vue";
import ModalAppScreenshots from "@/views/Analyze/AnalyzeApp/components/ModalAppScreenshots";
import {httpRequest} from "@/api";
import ModalAppBottomDetails from "@/views/CompetitorsFinderPage/components/ModalAppBottomDetails/index.vue";
import TextMetadata from "@/components/AppDetailsComponent/components/TextMetadata/index.vue";
import MetricsData from "@/components/AppDetailsComponent/components/MetricsData/index.vue";
import ModalAppMetricsNew from "@/views/Analyze/AnalyzeApp/components/ModalAppMetrics";
import SvgIcon from "@/components/UI/SvgIcon/index.vue";
import {shortenDigits} from "@/utils/utils";

export default {
  name: "Overview",
  components: {
    SvgIcon,
    ModalAppMetricsNew,
    MetricsData,
    TextMetadata,
    ModalAppScreenshots,
    PreloaderTableSmall,
    CustomDropdown,
    ModalAppBottomDetails
  },
  props: {
    appData: {
      type: Object,
    },
    filterCountriesArray: {
      type: Array
    },
    filterLocalesArray: {
      type: Array
    }
  },
  data() {
    return {
      currentDevice: 'iphone',
      screenshotsDataLoaded: false,
      closeLocalesList: 0,
      countryMainLocale: {},
      appScreenshotsData: {},
      screenshotsLocales: [],
      metricsDataLoaded: false,
      appMetricsData: {},
    }
  },
  mounted() {
    this.fetchAppMetricsData();
    this.fetchFilledLocales();
  },
  methods: {
    async fetchAppMetricsData() {
      this.metricsDataLoaded = false;
      const appMetricsData = await httpRequest(
        'GET',
        process.env.VUE_APP_API_URL + this.$ApiUrls.competirorsFinder.APP_METRICS_BLOCK_NEW
          + '?country_code=' + this.currentCountry?.code + '&app_id=' + this.appData?.id
      );

      if (JSON.stringify(appMetricsData) !== '{}') {
        this.appMetricsData = {
          category_visibility_percent: appMetricsData?.category_visibility_percent?.toFixed(0),
          visibility: appMetricsData?.visibility ? shortenDigits(appMetricsData.visibility) : '',
          voted: appMetricsData?.voted ? shortenDigits(appMetricsData.voted) : '',
          installs: appMetricsData?.installs ? shortenDigits(appMetricsData.installs) : 0,
          revenue: appMetricsData?.revenue ? shortenDigits(appMetricsData.revenue) : 0,
          rating: appMetricsData?.rating?.toFixed(1),
        };
      }

      this.metricsDataLoaded = true;
    },
    changeDevice(device) {
      this.currentDevice = device;
      this.screenshotsDataLoaded = false;

      setTimeout(() => {
        this.screenshotsDataLoaded = true;
      }, 300);
    },
    localeItemClicked(item) {
      this.closeLocalesList++;
      this.countryMainLocale = {...item, title: item.value};
    },
    setCurrentCountryLocale() {
      const currentCountryMainLocale = this.filterCountriesArray.find(country => country?.code === this.currentCountry?.code);

      if (currentCountryMainLocale?.locales?.main) {
        const localeName = this.filterLocalesArray.find(locale => locale.code === currentCountryMainLocale?.locales?.main);
        this.countryMainLocale = {
          code: currentCountryMainLocale?.locales?.main,
          title: localeName?.name,
        };
      } else {
        const localeName = this.filterLocalesArray.find(locale => locale.code === 'en');
        this.countryMainLocale = {
          code: localeName?.code,
          title: localeName?.name,
        };
      }
    },
    async fetchAppScreenshotsData() {
      this.screenshotsDataLoaded = false;
      this.appScreenshotsData = [];

      const appScreenshotsData = await httpRequest(
        'GET',
        process.env.VUE_APP_API_URL + this.$ApiUrls.competirorsFinder.APP_SCREENSHOTS
          + '?country_code=' + this.currentCountry?.code + '&app_id=' + this.appData.id + '&locale_code=' + this.countryMainLocale.code
      );

      if (JSON.stringify(appScreenshotsData) !== '{}' && appScreenshotsData?.list?.length > 0) {
        this.appScreenshotsData = {...appScreenshotsData};
      }

      this.screenshotsDataLoaded = true;
    },
    async fetchFilledLocales() {
      this.screenshotsLocales  = await httpRequest(
        'GET',
        process.env.VUE_APP_API_URL + this.$ApiUrls.competirorsFinder.APP_FILLED_LOCALES + '/' + this.appData.id
      );
    },
    decodeLocaleItem(fullLocalesArray, code) {
      let localeString = '';
      const localeFullData = fullLocalesArray.find(item => item?.code?.toLowerCase() === code?.toLowerCase());
      if (localeFullData) {
        return localeString = localeFullData?.name;
      } else {
        return code;
      }
    },
    openPlansModal() {
      this.$store.dispatch('INVOKE_USER_LIMITS_MANUAL');
    },
  },
  computed: {
    ...mapGetters([
      'currentApp',
      'currentCountry',
      'currentCountryManual',
    ]),
    ...mapGetters('keywordsTracking', [
      'competitorsResults',
    ]),
    hideInstalls() {
      return this.userSubscription?.plan_name === 'Free';
    },
    screenshotsComputed() {
      if (this.appData?.store !== 'APP_STORE') {
        return this.appScreenshotsData?.list;
      }

      const currentDevice = this.currentDevice.toLowerCase();
      let key = null;
      if (this.appScreenshotsData?.list_by_device) {
        key = Object.keys(this.appScreenshotsData?.list_by_device).find((device) => {
          return device.slice(0, this.currentDevice.length).toLowerCase() === currentDevice;
        });
      }

      return key ? this.appScreenshotsData.list_by_device[key] : [];
    },
    windowWidth() {
      return window.innerWidth;
    },
    preparedScreenshotsLocales() {
      let preparedLocales = [];
      this.screenshotsLocales.forEach(code => {
        let splittedCode = code.split('_');

        preparedLocales.push({
          code: code,
          value: this.decodeLocaleItem(this.filterLocalesArray, code),
          countryValue: splittedCode?.length > 1 ? this.decodeLocaleItem(this.filterCountriesArray, splittedCode[1]) : null,
        });
      });

      return preparedLocales;
    },
  },
  watch: {
    async countryMainLocale() {
      await this.fetchAppScreenshotsData();
    },
    filterCountriesArray() {
      if (this.filterCountriesArray.length > 0) {
        this.setCurrentCountryLocale();
      }
    },
    async currentCountryManual(newVal, oldVal) {
      if (newVal?.code !== oldVal?.code) {
        this.setCurrentCountryLocale();
        await this.fetchAppMetricsData();
      }
    },
    async currentApp(newVal, oldVal) {
      if (newVal?.id !== oldVal?.id) {
        await this.fetchAppMetricsData();
      }
    },
  }
}
</script>

<style lang="scss" src="./styles.scss"></style>
